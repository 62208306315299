<template>
	<div>
		<ly-dialog v-model="dialogVisible" :title="loadingTitle" width="880px" @closed="handleClose">
		 <div class="table">
			<el-table border :data="dataList" ref="tableref" v-loading="loadingPage"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column type="index" width="60" align="center" label="序号">
					<template #default="scope">
						<span v-text="getIndex(scope.$index)"></span>
					</template>
				</el-table-column>
				
				<el-table-column width="150" prop="nick_name" label="微信昵称"></el-table-column>
				<el-table-column width="270" prop="create_datetime" label="加入时间"></el-table-column>
				<el-table-column width="150" prop="level" label="下线级别"></el-table-column>
				<!-- <el-table-column width="150" prop="city_name" label="收货城市"></el-table-column> -->

				<!-- <el-table-column min-width="100" prop="userinfo.kefuname" label="所属客服"></el-table-column> -->
				<el-table-column  label="推广关系状态" align="center">
					<template #default="scope">
						<el-tag v-if="scope.row.status">正常</el-tag>
						<el-tag v-if="!scope.row.status">不再返佣</el-tag>
						<!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							@change="changeStatus(scope.row)">
						</el-switch> -->
					</template>
				</el-table-column>
			
				
			</el-table>
		</div>
		<Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
		</ly-dialog>
	</div>
</template>

<script>
	import LyDialog from "@/components/dialog/dialog";
	import Pagination from "@/components/Pagination";
	import {loadPromotionShipList} from "@/api/customer"
	export default {
		components: {
			LyDialog,
			Pagination,
			
		},
		name: 'promoteRecord',
		data() {
			return {
				loadingTitle:"",
				dialogVisible:false,
				isFull: false,
				tableHeight: 500,
				loadingPage: false,
				formInline: {
					page: 1,
					limit: 10,
					customer_id:0
				},
				dataList:[],
				pageparm: {
					page: 1,
					limit: 10,
					total: 0
				},
				statusList: [{
						id: 1,
						name: '正常'
					},
					{
						id: 0,
						name: '禁用'
					}
				],
				
				timers: [],
				tableData: [],
				isDialogVisible: false,
				
			}
		},

		methods: {
			handleOpen(customer_id,customer_name){
				this.formInline.customer_id=customer_id
				this.getData()
				this.dialogVisible=true
				this.loadingTitle=customer_name+"--推广记录"
			},
			handleClose(){
				this.dataList=[]
				this.dialogVisible=false
				
			},
			handleSelectionChange(data_list) {
				
			},
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			setFull() {
				this.isFull = !this.isFull
				window.dispatchEvent(new Event('resize'))
			},
			changeStatus(row) {
				let msg = ""
				let act = ""
				if (!row.status) {
					msg = "确定拉黑该用户吗，拉黑后用户将不能登录和下单交易"
					act = "stop"
				}
				if (row.status) {
					msg = "确定恢复该用户的正常使用状态吗"
					act = "start"
				}


				// let flat = row.status
				// row.is_active = !row.is_active
				this.$confirm(msg, '提醒', {
					closeOnClickModal: false,
					type: 'warning'
				}).then(res => {
					manageCustomerStatusApi({
						id: row.id,
						act: act
					}).then(res => {
						if (res.code == 2000) {
							// flat ? row.is_active = true : row.is_active = false
							this.$message.success(res.msg)
							this.getData()
						} else {
							row.status = !row.status
							this.$message.warning(res.msg)
						}
					})
				}).catch(() => {

				})
			},
			handelAddClick() {
				this.isDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addUserFlag.handleOpen(null, "新增")
				})
			},
			
			/**
			 * 从URL里下载文件
			 */
			// 下载文件
			downloadFileURL(url) {
				var iframe = document.createElement("iframe")
				iframe.style.display = "none";
				iframe.src = url;
				document.body.appendChild(iframe);
			},
			exportDataBackend() {
				var params = {
					page: 1,
					limit: 9999,
				}
				// UsersUsersExportexecl(params).then(res => {
				//     if (res.code == 2000) {
				//         this.downloadFileURL(res.data.data)
				//         //this.$message.warning(res.data.data)
				//     }
				// })
			},
			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				
				this.getData()
			},
			search() {
				this.formInline.page = 1
				this.formInline.limit = 10
				this.getData()
			},
			//获取列表
			async getData() {
				this.loadingPage = true
				loadPromotionShipList(this.formInline).then(res => {

					this.loadingPage = false
					if (res.code == 1) {
                      
							this.dataList = res.data.data_list
							
							this.pageparm.total = res.data.total;
							
						if (this.dataList &&  this.dataList.length > 0) {
							this.pageparm.page = this.formInline.page
							
						}
					}
				})
			},

			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
				tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
				this.tableHeight = getTableHeight(tabSelectHeight)
			}

		},
		created() {
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			// window.addEventListener('resize', this.listenResize);
			// this.$nextTick(() => {
			// 	this.getTheTableHeight()
			// })
		},
		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>

<style>
</style>