<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                
                <el-form-item v-if="formData.id>0" label="姓名：" prop="nick_name">
                    <el-input v-model="formData.nick_name"></el-input>
                </el-form-item>
				<el-form-item v-if="formData.id>0" label="电话：" prop="phone">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
				
				<el-form-item label="优惠券：" prop="coupon_id_list">
                    <el-select size="default" v-model="formData.coupon_id_list" clearable placeholder="请选择" multiple>
                        <el-option
                            v-for="item in coupon_list"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
				
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { UsersUsersAdd, UsersUsersEdit } from "@/api/api";
import {loadCouponListApi,addCustomerCouponApi} from '@/api/coupon'

import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
    name: "addCustomerCoupon",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                id:0,
                nick_name: '',
				customer_id_list:[],
				customer_name_str:'',
                phone: '',
                coupon_id_list:[],
            },
			coupon_list:[],
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                is_active: [
                    { required: true, message: '请选择是否启用', trigger: 'blur' }
                ]
            },
            rolelist: [],
            options: [],
        }
    },
    methods: {
        handleClose() {
			this.formData={
                id:0,
                nick_name: '',
				customer_id_list:[],
				customer_name_str:'',
                phone: '',
                coupon_id_list:[],
            }
            this.$emit('closed')
        },
        handleOpen(item, flag) {
			this.getData()
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
				
                this.formData = item
				
            } 
        },
        submitData() {
			let msg="确定向选中的用户派发优惠券吗？本次将向未领取的用户派发一张优惠券,如用户已获得，将按照优惠券设置的每人限领张数进行判断是否进行发放！"
			this.$confirm(msg, '提醒', {
			    closeOnClickModal: false,
			    type: 'warning'
			}).then(res => {
			   this.$refs['rulesForm'].validate(obj => {
			       if (obj) {
			        this.loadingSave = true
			        
			   		let customer_id_list=[]
					if (this.formData.id>0){
						customer_id_list.push(this.formData.id)
					}else{
						customer_id_list=this.formData.customer_id_list
					}
			   		
			   		addCustomerCouponApi({"customer_id_list":customer_id_list,"coupon_id_list":this.formData.coupon_id_list}).then(res=>{
			   			
			   			this.loadingSave = false
			   			if(res.code==1){
			   				this.$message({message:res.msg,type:"success"})
			   				this.$emit('refreshData')
							this.handleClose()
			   			}else{
			   				this.$message(res.msg)
			   			}
			   			
			   		})
			   		
			           
			   
			       }
			   })
			}).catch(() => {
			
			})
			
           
        },
		getData() {
			
			loadCouponListApi({page:1,limit:100,status:1}).then(res => {
				
				if (res.code == 1) {
					this.coupon_list = res.data.data_list
					
				}
			})
		},
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

